import debounce from 'lodash.debounce';
import moment from 'moment-mini';
import { v4 as uuidv4 } from 'uuid';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';

import { divide } from '../../../shared/utils/calculate';
import { getFormatDate } from '../../../shared/utils/date';
import { FieldsEditRestriction } from '../../../shared/utils/fieldEditRestriction';

import { generateOrderId, getEditableObject } from '../helpers';
import confirm from '../helpers/confirm';
import { calculateExpectedDeadlineDate } from '../helpers/expectedDeadlineDate';
import { normalizeAddressString } from '../helpers/normalizeAddress';
import { checkCalendar } from '../helpers/rules';
import { AddressAutoCompleteEntry, PointGeometry } from '../helpers/types/index';
import { Order, OrderItem, OrderType } from '../helpers/types/order';
import ApiService from '../services/ApiService';
import { RootState } from './';
import { Product } from './product.module';

type RecipientPhone = {
  orderId: string;
  phone?: string;
};

type State = {
  editedOrder?: Order;
  editedOrderRecipientPhone?: RecipientPhone;
  vehicles?: object[];
  isAddressAutoCompleteLoading: boolean;
  addressAutoCompleteEntries?: AddressAutoCompleteEntry[];
  addressAutoCompleteEntry?: AddressAutoCompleteEntry;
  isEditMode: boolean;
  isSaving: boolean;
  editable: boolean;
  deliveryAddress?: string;
  products?: Product[];
  oldReleaseDate?: Date;
  oldExpectedDeadlineDate?: Date;
  isDatesInvalid: boolean;
  changed: number;
  oldOrderItems?: OrderItem[];
  latestReleaseDate?: Date;
};

const initialState: State = {
  editedOrder: undefined,
  editedOrderRecipientPhone: undefined,
  vehicles: [],
  isAddressAutoCompleteLoading: false,
  addressAutoCompleteEntries: [],
  addressAutoCompleteEntry: undefined,
  isEditMode: false,
  isSaving: false,
  editable: false,
  deliveryAddress: undefined,
  products: undefined,
  oldReleaseDate: undefined,
  oldExpectedDeadlineDate: undefined,
  isDatesInvalid: false,
  changed: 0,
  oldOrderItems: [],
  latestReleaseDate: undefined
};

const state: State = { ...initialState };

const actions: ActionTree<State, RootState> = {
  resetState({ commit }) {
    commit('resetState');
  },
  newOrder({ commit, rootGetters, rootState }) {
    const currentDate = new Date(Date.now());
    const orderDeadlineDate = new Date(
      new Date(currentDate).setDate(
        currentDate.getDate() + rootGetters['basicSettings/deliveryInterval']
      )
    );
    while (checkCalendar(orderDeadlineDate) !== true) {
      orderDeadlineDate.setDate(orderDeadlineDate.getDate() - 1);
    }
    const editedOrder: Order = {
      customer: {},
      status: rootState.app.enums.orderStatus.new,
      orderItems: [],
      orderDate: currentDate,
      orderDeadlineDate: orderDeadlineDate,
      deliveryToSite: false,
      type: OrderType.Freight,
      externalIds: {}
    };
    commit('setNewOrder', editedOrder);
    commit('addNewOrderItem');
  },

  setOrder({ commit, rootState }, order: Order) {
    if (!order.externalIds) {
      order.externalIds = {};
      rootState.externalIdNames.orderExternalIds?.forEach(
        (externalIdName) => (order.externalIds![externalIdName.name] = '')
      );
    }

    commit('setOrder', order);
  },

  setEditedRecipientPhoneNumber({ commit }, recipientPhone: RecipientPhone) {
    commit('setEditedOrderRecipientPhone', recipientPhone);
  },

  async updateRecipientPhone({ commit }) {
    commit('setIsSaving', true);
    await ApiService.updateOrderRecipientPhone(
      state.editedOrderRecipientPhone?.phone,
      state.editedOrderRecipientPhone?.orderId
    );
    commit('setIsSaving', false);
  },

  addNewOrderItem({ commit }) {
    commit('addNewOrderItem');
  },

  async getVehicles({ commit }) {
    const { data } = await ApiService.getVehicles();
    commit('setVehicles', data);
  },
  refreshAddressEntries: debounce(async function ({ commit }, value) {
    commit('setIsAddressAutoCompleteLoading', true);
    try {
      const response = await ApiService.locationSearch(value);
      const result: any = [];
      response.data.result.features.forEach((item: any) => {
        result.push({
          address: normalizeAddressString(value, item.properties),
          geometry: item.geometry
        });
      });
      commit('setAddressAutoCompleteEntries', result);
    } catch (ex) {
      commit('setAddressAutoCompleteEntries', []);
    } finally {
      commit('setIsAddressAutoCompleteLoading', false);
    }
  }, 300),
  async calculateOrderExpectedDeadlineDate({ commit, state, rootGetters }) {
    if (
      !state.editedOrder?.expectedDeadlineDate ||
      (state.editedOrder?.releaseDate &&
        state.editedOrder?.releaseDate > state.editedOrder?.expectedDeadlineDate)
    ) {
      if (state.editedOrder?.releaseDate) {
        const newDate = calculateExpectedDeadlineDate(
          state.editedOrder.releaseDate,
          rootGetters['basicSettings/deliveryIntervalType'],
          rootGetters['basicSettings/deliveryInterval']
        );
        commit('setExpectedDeadlineDate', moment(newDate).toISOString());
        commit('changed');
      }
    }
    if (
      state.editedOrder?.orderDeadlineDate &&
      state.editedOrder?.expectedDeadlineDate &&
      state.editedOrder?.orderDeadlineDate > state.editedOrder?.expectedDeadlineDate
    ) {
      commit('setExpectedDeadlineDate', state.editedOrder?.orderDeadlineDate);
      commit('changed');
    }
  },
  async calculateOrderItemExpectedDeadlineDate(
    { commit, rootGetters },
    editedOrderItem: OrderItem
  ) {
    if (
      (editedOrderItem?.deadlineDate &&
        editedOrderItem.releaseDate &&
        editedOrderItem.releaseDate > editedOrderItem.deadlineDate) ||
      (!editedOrderItem.deadlineDate && editedOrderItem.releaseDate)
    ) {
      const newDate = calculateExpectedDeadlineDate(
        editedOrderItem.releaseDate,
        rootGetters['basicSettings/deliveryIntervalType'],
        rootGetters['basicSettings/deliveryInterval']
      );
      commit('setOrderItemDeadlineDateById', {
        orderItemId: editedOrderItem.id,
        deadlineDate: moment(newDate).toISOString()
      });
      commit('changed');
    }
  },
  async setOrderItemReleaseDateIfEqual({ commit, rootGetters, state }) {
    if (
      state.editedOrder?.orderItems &&
      rootGetters['module/isOrderItemsReleaseDateFollowsOrderReleaseDateActive']
    ) {
      for (const orderItem of state.editedOrder.orderItems) {
        if (
          state.latestReleaseDate &&
          state.editedOrder.releaseDate &&
          getFormatDate(orderItem.releaseDate ?? '') === getFormatDate(state.latestReleaseDate)
        ) {
          commit('setEditedOrderItemReleaseDate', {
            id: orderItem.id,
            value: state.editedOrder.releaseDate
          });

          if (
            orderItem.deadlineDate &&
            orderItem.releaseDate &&
            orderItem.releaseDate > orderItem.deadlineDate
          ) {
            const newDate = calculateExpectedDeadlineDate(
              orderItem.releaseDate,
              rootGetters['basicSettings/deliveryIntervalType'],
              rootGetters['basicSettings/deliveryInterval']
            );
            commit('setOrderItemDeadlineDateById', {
              orderItemId: orderItem.id,
              deadlineDate: moment(newDate).toISOString()
            });
          }
          commit('changed');
        }
      }
    }
  },

  async setLatestReleaseDate({ commit }, releaseDate: Date) {
    commit('setLatestReleaseDate', releaseDate);
  },

  async update({ commit, rootGetters, rootState }) {
    try {
      commit('setIsSaving', true);

      const config = rootState.app.config;
      const fieldEditRestrictions = FieldsEditRestriction.init(config);
      const editedOrderData = await getEditableObject(
        state.editedOrder ? state.editedOrder : {},
        rootGetters['account/role'],
        fieldEditRestrictions.ORDER_EDIT_MODULE_DEPENDENCIES,
        fieldEditRestrictions.ORDER_FIELDS_EDIT_RESTRICTIONS
      );

      // round up orderItems' palletsCount to be an integer
      if (editedOrderData.orderItems && editedOrderData.orderItems.length > 0) {
        for (const item of editedOrderData.orderItems) {
          const productOnItem = state.products?.find((product) => product.itemId === item.itemId);
          if (item.palletsCount !== undefined && productOnItem?.unitPerPallet) {
            item.palletsCount = Math.ceil(divide(item.quantity, productOnItem.unitPerPallet));
          }
        }
      }

      await ApiService.updateOrder(editedOrderData, state.editedOrder?.orderId);
      commit('setIsSaving', false);
    } catch (error: any) {
      commit('setIsSaving', false);
      throw error;
    }
  },

  async saveOrder({ commit, dispatch }) {
    try {
      commit('setIsSaving', true);
      const orderToSave: Order = JSON.parse(JSON.stringify(state.editedOrder));
      orderToSave.orderId = generateOrderId(orderToSave.orderDate, orderToSave.factoryShortName);

      for (const item of orderToSave.orderItems) {
        delete item.id;

        const productOnItem = state.products?.find((product) => product.itemId === item.itemId);
        if (item.palletsCount !== undefined && item.quantity && productOnItem?.unitPerPallet) {
          item.palletsCount = Math.ceil(divide(item.quantity, productOnItem.unitPerPallet));
        }
      }

      orderToSave.preferredTrucks = orderToSave.preferredTrucks ?? [];

      // TODO WB?
      if (orderToSave.externalIds?.incoterm) {
        orderToSave.deliveryToSite = ['Z', 'X'].includes(orderToSave.externalIds.incoterm);
      }

      await ApiService.saveOrder(orderToSave);

      commit('setIsSaving', false);

      dispatch(
        'alert/success',
        `Új megrendelés sikeresen felvéve a(z) ${orderToSave.orderId} azonosítóval!`,
        {
          root: true
        }
      );
    } catch (error: any) {
      commit('setIsSaving', false);
      throw error;
    }
  },
  isEditable({ commit, rootState }) {
    commit(
      'setEditable',
      state.editedOrder?.status === rootState.app.enums.orderStatus.new ||
        state.editedOrder?.status === rootState.app.enums.orderStatus.invalid
    );
  },
  updateAddress({ commit }, value) {
    if (value) {
      commit('setAddress', value);
    }
  },
  changeIsEditMode({ commit, dispatch }, value) {
    if (state.isEditMode && state.editedOrder?.orderItems) {
      for (const orderItem of state.editedOrder.orderItems) {
        dispatch('calculateFromQuantity', {
          orderItemId: orderItem.id,
          value: orderItem.quantity,
          productId: orderItem.itemId
        });
      }
    }
    commit('setIsEditMode', value);
  },
  async setDefaultVehicles({ commit }) {
    const { data }: { data: Array<string> } = await ApiService.getDefaultVehiclesByDeliveryToSite(
      state.editedOrder?.deliveryToSite
    );

    commit('setPreferredTrucks', data);
  },
  async getProducts({ commit }) {
    const { data } = await ApiService.getProducts();
    commit('setProducts', data);
  },
  calculateFromKg(
    { dispatch, commit, rootState },
    value: { orderItemId: string; value: number; productId: string }
  ) {
    const product = state.products?.find((product) => product.itemId === value.productId);
    if (product && product.kgPerUnit && product.unitPerPallet && product.kgPerPallet) {
      const newPalletsCount1 =
        Math.round((value.value / (product.unitPerPallet * product.kgPerUnit)) * 10) / 10;
      const newPalletsCount2 = Math.round((value.value / product.kgPerPallet) * 10) / 10;
      commit('setEditedOrderItemPalletsCount', {
        orderItemId: value.orderItemId,
        value: newPalletsCount1 < newPalletsCount2 ? newPalletsCount1 : newPalletsCount2
      });
      if (
        rootState.app.config?.units[product.unit || '']?.isOnlyInteger === false ||
        Number.isInteger(value.value / product.kgPerUnit)
      ) {
        const newQuantity = Number((value.value / product.kgPerUnit).toFixed(1));
        commit('setEditedOrderItemQuantity', {
          orderItemId: value.orderItemId,
          value: newQuantity
        });
      } else {
        // if the entered kg yields a decimal quantity in case of a 'piece' product,
        // we round it up to the nearest integer, and correct the others
        const newQuantity = Math.ceil(value.value / product.kgPerUnit);
        commit('setEditedOrderItemQuantity', {
          orderItemId: value.orderItemId,
          value: newQuantity
        });
        dispatch('calculateFromQuantity', {
          orderItemId: value.orderItemId,
          value: newQuantity,
          productId: value.productId
        });
      }
    }
  },
  calculateFromQuantity(
    { commit },
    value: { orderItemId: string; value: number; productId: string }
  ) {
    const product = state.products?.find((product) => product.itemId === value.productId);
    const editedOrderItem = state.editedOrder?.orderItems?.find(
      (orderItem) => orderItem.id === value.orderItemId
    );
    if (
      product &&
      product.kgPerUnit &&
      product.unitPerPallet &&
      product.kgPerPallet &&
      editedOrderItem
    ) {
      commit('setEditedOrderItemKg', {
        orderItemId: value.orderItemId,
        value: Number((value.value * product.kgPerUnit).toFixed(3))
      });

      commit('setEditedOrderItemPalletsCount', {
        orderItemId: value.orderItemId,
        value: Math.round((value.value / product.unitPerPallet) * 10) / 10
      });

      commit('setEditedOrderItemQuantity', {
        orderItemId: value.orderItemId,
        value: Number(editedOrderItem.quantity)
      });
    }
  },
  calculateFromPalletsCount(
    { dispatch, commit, rootState },
    value: { orderItemId: string; value: number; productId: string }
  ) {
    const product = state.products?.find((product) => product.itemId === value.productId);
    const editedOrderItem = state.editedOrder?.orderItems?.find(
      (orderItem) => orderItem.id === value.orderItemId
    );
    if (product && product.kgPerUnit && product.unitPerPallet && editedOrderItem) {
      if (
        rootState.app.config?.units[product.unit || '']?.isOnlyInteger === false ||
        Number.isInteger(value.value * product.unitPerPallet)
      ) {
        const newQuantity = Number((value.value * product.unitPerPallet).toFixed(1));
        commit('setEditedOrderItemQuantity', {
          orderItemId: value.orderItemId,
          value: newQuantity
        });
        const newKg = Number((newQuantity * product.kgPerUnit).toFixed(3));
        commit('setEditedOrderItemKg', { orderItemId: value.orderItemId, value: newKg });
      } else {
        // if the entered palletCount yields a decimal quantity in case of a 'piece' product,
        // we round it up to the nearest integer, and correct the others
        const newQuantity = Math.ceil(value.value * product.unitPerPallet);
        commit('setEditedOrderItemQuantity', {
          orderItemId: value.orderItemId,
          value: newQuantity
        });
        dispatch('calculateFromQuantity', {
          orderItemId: value.orderItemId,
          value: newQuantity,
          productId: value.productId
        });
      }
      commit('setEditedOrderItemPalletsCount', {
        orderItemId: value.orderItemId,
        value: Number(editedOrderItem.palletsCount)
      });
    }
  },
  changeEditedOrderItemName(
    { commit, dispatch },
    value: { orderItemId: string; productId: string }
  ) {
    commit('setEditedOrderItemName', value);
    dispatch('calculateFromKg', {
      orderItemId: value.orderItemId,
      value: state.editedOrder?.orderItems?.find((orderItem) => orderItem.id === value.orderItemId)
        ?.kg,
      productId: value.productId
    });
    commit('setEditedOrderItemUnit', {
      productId: value.productId,
      orderItemId: value.orderItemId
    });
  },
  changeEditedOrderItemItemId(
    { commit, dispatch },
    value: { orderItemId: string; productName: string }
  ) {
    commit('setEditedOrderItemItemId', value);
    const productId = state.products?.find((product) => product.name === value.productName)?.itemId;
    dispatch('calculateFromPalletsCount', {
      orderItemId: value.orderItemId,
      value: state.editedOrder?.orderItems?.find((orderItem) => orderItem.id === value.orderItemId)
        ?.palletsCount,
      productId: productId
    });
    commit('setEditedOrderItemUnit', { productId: productId, orderItemId: value.orderItemId });
  },
  async deleteOrderItem({ commit, dispatch }, value) {
    if (state.editedOrder?.orderItems?.length === 1) {
      dispatch('alert/success', 'Legalább 1 tételnek kell szerepelni egy rendelésben!', {
        root: true
      });
    } else {
      const answer = await confirm('Biztosan törli a kiválasztott tételt?', {
        title: 'Tétel törlése',
        width: 375
      });
      if (answer) {
        commit('removeOrderItem', value);
      }
    }
  },
  setEditedOrderType({ commit }, orderType: OrderType) {
    commit('setEditedOrderType', orderType);
  }
};

const mutations: MutationTree<State> = {
  setEditedOrderRecipientPhone(state, payload: RecipientPhone) {
    state.editedOrderRecipientPhone = payload;
  },
  setVehicles(state, payload: object[]) {
    state.vehicles = payload;
  },
  setPreferredTrucks(state, preferredTrucks: Array<string>) {
    if (!state.editedOrder) {
      return;
    }

    state.editedOrder.preferredTrucks = preferredTrucks;
  },
  setIsAddressAutoCompleteLoading(state, payload: boolean) {
    state.isAddressAutoCompleteLoading = payload;
  },
  setAddressAutoCompleteEntries(state, payload: AddressAutoCompleteEntry[]) {
    state.addressAutoCompleteEntries = payload;
  },
  setExpectedDeadlineDate(state, payload: Date) {
    if (state.editedOrder) {
      state.editedOrder.expectedDeadlineDate = payload;
    }
  },
  changed(state) {
    state.changed++;
  },
  setOldReleaseDate(state, payload: Date) {
    state.oldReleaseDate = payload;
  },
  setOldExpectedDeadlineDate(state, payload: Date) {
    state.oldExpectedDeadlineDate = payload;
  },
  setIsDatesInvalid(state, payload: boolean) {
    state.isDatesInvalid = payload;
  },
  setOrder(state, order: Order) {
    state.editedOrder = { ...order };
    state.deliveryAddress = order.deliveryAddress;
    state.oldReleaseDate = order.releaseDate;
    state.oldExpectedDeadlineDate = order.expectedDeadlineDate;
    state.oldOrderItems = JSON.parse(JSON.stringify(order.orderItems));
  },
  setNewOrder(state, editedOrder: Order) {
    state.editedOrder = JSON.parse(JSON.stringify(editedOrder));
  },
  setIsSaving(state, payload: boolean) {
    state.isSaving = payload;
  },
  setEditable(state, payload: boolean) {
    state.editable = payload;
  },
  setAddress(state, payload: { address: string; geometry: PointGeometry }) {
    if (state.editedOrder) {
      state.editedOrder.deliveryAddress = payload.address;
      state.editedOrder.deliveryCoords = payload.geometry.coordinates;
    }
  },
  setIsEditMode(state, payload: boolean) {
    state.isEditMode = payload;
  },
  setProducts(state, payload: Product[]) {
    state.products = payload;
  },

  setOrderItemDeadlineDateById(state, payload: { orderItemId: string; deadlineDate: Date }) {
    const orderItem = state.editedOrder?.orderItems.find(
      (orderItem) => orderItem.id === payload.orderItemId
    );

    if (orderItem) {
      orderItem.deadlineDate = payload.deadlineDate;
    }
  },

  setEditedOrderItemName(state, payload: { orderItemId: string; productId: string }) {
    const newProduct = state.products?.find((product) => product.itemId === payload.productId);
    const editedOrderItem = state.editedOrder?.orderItems?.find(
      (orderItem) => orderItem.id === payload.orderItemId
    );
    if (editedOrderItem && newProduct) {
      editedOrderItem.name = newProduct.name;
      editedOrderItem.kg = 1;
    }
  },
  setEditedOrderItemItemId(state, payload: { orderItemId: string; productName: string }) {
    const newProduct = state.products?.find((product) => product.name === payload.productName);
    const editedOrderItem = state.editedOrder?.orderItems?.find(
      (orderItem) => orderItem.id === payload.orderItemId
    );
    if (editedOrderItem && newProduct) {
      editedOrderItem.itemId = newProduct.itemId;
      editedOrderItem.palletsCount = 1;
    }
  },
  setEditedOrderItemUnit(state, payload: { productId: string | undefined; orderItemId: string }) {
    if (payload.productId) {
      const product = state.products?.find((product) => product.itemId === payload.productId);
      const editedOrderItem = state.editedOrder?.orderItems?.find(
        (orderItem) => orderItem.id === payload.orderItemId
      );
      if (product && editedOrderItem) {
        editedOrderItem.unit = product.unit;
        editedOrderItem.palletType = product.palletType;
      }
    }
  },
  removeOrderItem(state, payload: OrderItem) {
    if (state.editedOrder?.orderItems) {
      const index = state.editedOrder.orderItems.indexOf(payload, 0);
      if (index > -1) {
        state.editedOrder.orderItems.splice(index, 1);
      }
    }
  },
  setEditedOrderItemPalletsCount(state, payload: { orderItemId: string; value: number }) {
    if (state.editedOrder?.orderItems) {
      const editedOrderItem = state.editedOrder.orderItems.find(
        (orderItem) => orderItem.id === payload.orderItemId
      );
      if (editedOrderItem) {
        editedOrderItem.palletsCount = payload.value;
      }
    }
  },
  setEditedOrderItemQuantity(state, payload: { orderItemId: string; value: number }) {
    if (state.editedOrder?.orderItems) {
      const editedOrderItem = state.editedOrder.orderItems.find(
        (orderItem) => orderItem.id === payload.orderItemId
      );
      if (editedOrderItem) {
        if (payload.value === 0) {
          editedOrderItem.quantity = 1;
        } else {
          editedOrderItem.quantity = payload.value;
        }
      }
    }
  },
  setEditedOrderItemKg(state, payload: { orderItemId: string; value: number }) {
    if (state.editedOrder?.orderItems) {
      const editedOrderItem = state.editedOrder.orderItems.find(
        (orderItem) => orderItem.id === payload.orderItemId
      );
      if (editedOrderItem) {
        if (payload.value === 0) {
          editedOrderItem.kg = 1;
        } else {
          editedOrderItem.kg = payload.value;
        }
      }
    }
  },
  addNewOrderItem(state) {
    state.editedOrder?.orderItems?.push({
      id: uuidv4() + '_NEW',
      itemId: undefined,
      name: undefined,
      quantity: undefined,
      unit: undefined,
      kg: undefined,
      palletsCount: undefined
    });
  },
  setEditedOrderItemReleaseDate(state, payload) {
    const editedOrderItem = state.editedOrder?.orderItems?.find(
      (orderItem) => orderItem.id === payload.id
    );
    if (editedOrderItem) {
      editedOrderItem.releaseDate = payload.value;
    }
  },
  setLatestReleaseDate(state, releaseDate: Date) {
    state.latestReleaseDate = releaseDate;
  },
  setOldOrderItems(state, payload) {
    state.oldOrderItems = JSON.parse(JSON.stringify(payload));
  },
  resetState(state) {
    Object.assign(state, initialState);
  },
  setEditedOrderType(state, orderType: OrderType) {
    if (!state.editedOrder) {
      return;
    }
    state.editedOrder.type = orderType;
  },
  updateField
};

const getters: GetterTree<State, RootState> = {
  editedOrder: (state) => state.editedOrder,
  getField
};

export const orderEdit = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
